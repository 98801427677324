.table-guia {
    .row-table {
        .id {
            margin: 0;
            color: $gray-700;
        }
        .name {
            margin: 0;
            text-transform: capitalize;
            font-weight: 700;
            font-size: 15px;
        }
        .info{
            margin: 0;
            color: $gray-700;
            span {
                text-transform: lowercase;
                font-weight: 700;
                color: $gray-900;
                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }
        td {
            vertical-align: middle;
        }
    }
}