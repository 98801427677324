.create-size-charts {
    .info {
        margin: 0;
        color: $gray-700;

        span {
            text-transform: lowercase;
            font-weight: 700;
            color: $gray-900;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .button-comeback {
        color: $primary;
    }

    .table-create-size {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        padding-bottom: 85px;
        table-layout: fixed;

        .row-table {
            .column-title {
                position: sticky;
                top: 0 !important;
                background-color: $gray-400;
                text-align: center;
            }
            .column-info {
                background-color: $white;
                border-right: 1px solid $gray-300;
                text-align: center;
                padding: 0;

                input {
                    max-width: 100%;
                    text-align: center;
                    border: none;
                    &::placeholder {
                        color: $gray-300;
                    }
                    &[type=number] {
                        -moz-appearance: textfield;
                    }
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    &:focus-visible {
                        outline: none;
                    }
                }
                &:first-child {
                    //display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    input {
                        width: 20%;
                    }
                }
                &:not(:first-child) {
                    vertical-align: middle;
                }
            }

            th,
            td {
                &:first-child {
                    -webkit-border-radius: 5px 0 0 0;
                    border-radius: 5px 0 0 0;
                    border-right: 1px solid #ededed;
                    -webkit-box-shadow: 2px 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
                    box-shadow: 2px 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
                    -webkit-clip-path: inset(0 -10px 0 0);
                    clip-path: inset(0 -10px 0 0);
                    left: 0;
                    overflow: hidden;
                    position: -webkit-sticky;
                    position: sticky;
                    z-index: 24;
                }
            }
        }
    }

}

.group-buttons {
    .button-filter {
        min-width: 12rem;
    }
}