
@keyframes shine {
    to {
        background-position: 100% 0,
            0 0;
    }
}

.skeleton-table{
    background-color: #f2f2f2;
    border-radius: 10px;
    height: 500px;
    width: 100%;
    padding: 20px;
}
.skeleton-table-header {
    border-radius: 10px;
    width: 100%;
    height: 100px;
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%),
        lightgray;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
}
.skeleton-table-body{
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%),
        lightgray;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
    border-radius: 10px;
    height: 340px;
    width: 100%;
    margin-top: 20px;
}

.skeleton-card {
    border-radius: 10px;
    width: 100%;
    height: 400px;
    background-color:  #f2f2f2;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
}

.skeleton-card-image {
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%),
            #C6C6C6;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
}

.skeleton-card-title {
    margin: auto;
    margin-top: 20px;
    border-radius: 5px;
    width: 90%;
    height: 50px;
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%),
            #C6C6C6;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
}
.skeleton-card-body {
    margin: auto;
    margin-top: 20px;
    border-radius: 5px;
    width: 90%;
    height: 130px;
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%),
            #C6C6C6;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
}

.skeleton-block {
    border-radius: 10px;
    width: 100%;
    min-height: 100px;
    background-color:  #f2f2f2;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.skeleton-block-content-line {
    display: flex;
    margin: auto;
    margin-top: 10px;
    border-radius: 5px;
    width: 90%;
    height: 100px;
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%),
            #C6C6C6;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
}