

.big-select { 
  min-height: 400px;
}

.dropzone-box-styles {
  padding: 50px 10px;
}

.active-card {
  border: 2px solid $success;
}

.logo-image {
  max-width: 80px;
}

.bg-secondary-dark {
  background-color: #77787d;
}

.aligned-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overflow-x-scroll {
  overflow-x: scroll;
}


.fade-enter {
  opacity: 0.01;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-leave {
  opacity: 1;
}
.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.btn.without-caret.dropdown-toggle:after {
  display: none;
}

.custom-react-date .react-datepicker-wrapper {
  display: block;
}

#kt_header_mobile img {
  max-width: 155px;
}

.complete-button button {
  width: 100%;
}

.thumbnail-list {
  max-width: 100px;
}

.no-text-shadow {
  text-shadow: none;
}